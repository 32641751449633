<template>
  <div>
    <div v-if="$terminal">
      <div class="team-title">交易设置</div>
      <el-form :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               label-width="180px"
               class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="支付宝最低支付金额" prop="name">
              <el-input-number size="small" :min="500" :max="20000"
                               v-model="ruleForm.name"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付宝最高支付金额" prop="name">
              <el-input-number size="small" :min="500" :max="20000"
                               v-model="ruleForm.name"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="银行卡最低支付金额" prop="name">
              <el-input-number size="small" v-model="ruleForm.name"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="银行卡最高支付金额" prop="name">
              <el-input-number size="small" v-model="ruleForm.name"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="team-title">返佣设置</div>
      <el-form :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               label-width="180px"
               class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="返佣比例" prop="name">
              <el-input-number size="small" :min="0" :step="0.0001" :max="0.1"
                               v-model="ruleForm.scale"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-else>
      phone-setting
    </div>
  </div>
</template>

<script>
export default {
  name: "Setting",
  components:{},
  data() {
    return {
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        scale: 0,


      },
      rules: {},
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){},
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/conmmon-style.scss";
</style>

<template>
  <div>
    <div v-if="$terminal">
      <div class="header-d">
        <el-select v-model="form.userState"
                   clearable
                   @change="init"
                   class="common-left-space"
                   placeholder="用户状态">
          <el-option
              v-for="item in options"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-button class="common-left-space"
                   @click="form.account='';form.userState='';form.roleId='';init()"
                   type="info">
          <i class="el-icon-refresh"></i>
          重置搜索
        </el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            class="header-d-input"
            v-model="form.account"
            placeholder="支持帐号、昵称、姓名模糊搜索">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         align="center"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="success"
                         size="mini"
                         @click="controls('info',scope.row)">{{$t('table.seeBusiness')}}</el-link>
                <el-link type="primary"
                         class="common-left-space"
                         size="mini"
                         @click="controls('sort',scope.row)">{{$t('table.sort')}}</el-link>
                <el-link class="common-left-space"
                         type="danger"
                         size="mini"
                         @click="controls('black',scope.row)">{{$t('table.shield')}}</el-link>
              </template>
              <template v-if="item.params==='businessState'">
                {{scope.row.businessState|businessStateTransfer}}
              </template>
              <template v-if="item.params==='userState'">
                {{scope.row.userState|userStateTransfer}}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else>
      business
    </div>
    <SaveOrUpdateUser @reload="init" ref="saveOrUpdateUser"/>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser";
import constant from "@/constant";
import {businessPageAPI, businessSortAPI, userDeleteAPI, userPageAPI} from "@/api/user";
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import {controlAPI} from "@/api/Login";
export default {
  name: "Business",
  mixins:[common,dictTransfer],
  components:{SaveOrUpdateUser, paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"商家名称",prop:"nickName",width:'auto',template:false,params:''},
        {align:"center",label:"交易金额",prop:"balance",width:110,template:false,params:''},
        {align:"center",label:"总金额",prop:"payType",width:100,template:false,params:''},
        {align:"center",label:"剩余金额",prop:"payType",width:100,template:false,params:''},
        {align:"center",label:"商户状态",prop:"payType",width:100,template:false,params:''},
        {align:"center",label:"今日交易数",prop:"payType",width:100,template:false,params:''},
        {align:"center",label:"盈利金额",prop:"payType",width:100,template:false,params:''},
        {align:"center",label:"排名",prop:"sort",width:70,template:false,params:''},
        {align:"center",label:"操作",prop:"answer",width:270,template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        userState: '',
        account: '',
        roleId: '',
      },
      ids:[],
      options:constant.userState,
      roleList:constant.roleList,
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      this.form.roleId=2
      this.form.userState=1
      businessPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
      this.$refs.saveOrUpdateUser.dialogUser=true
    },
    controls(type,params){
      switch (type) {
          //todo 订单详情
        case 'info':
          break
        case 'black':
          controlAPI(2,params.id).then(res=>{
            this.popUp(res)
            if (res.code === 200) {
              this.init()
            }
          })
          break
          //修改
        case 'sort':
          this.$prompt('请输入商家排名', '商家排名', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /^\d+$/,
            inputErrorMessage: '输入格式不正确, 只能输入数字'
          }).then(({ value }) => {
            businessSortAPI(params.id,value).then(res=>{
              this.popUp(res)
              if (res.code === 200) {
                this.init()
              }
            })
          }).catch(() => {});
          break
      }
    },
    batchDelete(){
      userDeleteAPI(this.ids).then(res=>{
        this.ids=[]
        this.popUp(res)
        this.init()
      })
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>

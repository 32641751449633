<template>
  <div>
    <div class="content-main-padding">
      <RouterView/>
      <div v-if="(!$terminal) && ($route.path === '/admin' || $route.path === '/admin/')">
        <div @click="$router.push({path:item.path})"
                 v-for="(item,index) in threeMenu"
                 class="box-card">
          <i :class="item.icon"></i>
          <span class="text item">
            {{item.name}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAdminRouteList} from "@/router";

export default {
  name: "Admin",
  components:{},
  data() {
    return {
      threeMenu:getAdminRouteList()
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      if (this.$terminal) {
        if (this.$route.path === '/admin' || this.$route.path === '/admin/') {
          this.$router.push('/admin/user');
        }
      }
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.box-card{
  margin-top: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04),0 -1px 0px rgba(0, 0, 0, .05);
  padding: 25px 0;
}

</style>

export const rmap ={
    Layout:()=>import("@/components/Layout.vue"),
    marketplace:()=>import("@/views/marketplace/Marketplace.vue"),
    trading:()=>import("@/views/trading/Trading.vue"),
    admin:()=>import("@/views/admin/Admin.vue"),
    history:()=>import("@/views/history/History.vue"),
    userinfo:()=>import("@/views/info/UserInfo.vue"),
    adminUser:()=>import("@/views/admin/user/User.vue"),
    adminSetting:()=>import("@/views/admin/setting/Setting.vue"),
    adminBusiness:()=>import("@/views/admin/business/Business.vue"),
    businessList:()=>import("@/views/admin/business/BusinessList.vue"),
}

export const routerhandle = (routes)=>{
    return routes.map(ro => {
        let children = ro.children && ro.children.length > 1 ? routerhandle(ro.children) : []
        return {
            ...ro,
            meta: {title: ro.name, icon: ro.icon,id:ro.id},
            component: rmap[ro.name],
            children
        }
    })
}

<template>
  <div class="content-main-padding" style="padding-bottom: 0;padding-top: 0">
    <div class="header-d">
      <template v-for="(item,index) in payType">
        <div class="item-type"
             v-if="item.disabled===false"
             :class="ruleForm.payType===item.type?'item-type-active':''"
             @click="checkType(item.type)">
          <img :src="item.icon"
               :style="item.name === '银行卡'?{'width':'40px'}:''"
               class="img-pay"
               loading="eager"
               alt="32">
          <span class="type-pay">{{item.name}}</span>
        </div>
      </template>
    </div>
    <el-form :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             label-width="100px"
             class="demo-ruleForm">
      <el-form-item>
        <el-switch
            style="display: block"
            v-model="ruleForm.state"
            active-color="#13ce66"
            active-value="1"
            inactive-value="0"
            active-text="开始盈利"
            inactive-text="关闭盈利">
        </el-switch>
      </el-form-item>
      <el-form-item
          v-if="ruleForm.payType==='1'"
          label="支付宝帐号"
          prop="aliAccount">
        <el-input :class="{'apply-shake': valid.account}"
                  maxlength="11"
                  show-word-limit
                  placeholder="请输入支付宝账帐号"
                  v-model="ruleForm.aliAccount"></el-input>
      </el-form-item>
      <el-form-item
          v-if="ruleForm.payType==='1'"
          label="真实姓名"
          prop="realName">
        <el-input :class="{'apply-shake': valid.sign}"
                  placeholder="请输入真实姓名"
                  v-model="ruleForm.realName"></el-input>
      </el-form-item>
      <el-form-item v-if="ruleForm.payType==='2'"
                    label="银行卡号"
                    prop="bankAccount">
        <el-input minlength="16"
                  placeholder="请输入银行卡号"
                  :class="{'apply-shake': valid.account}"
                  maxlength="16"
                  show-word-limit
                  v-model="ruleForm.bankAccount"></el-input>
      </el-form-item>
      <el-form-item
          v-if="ruleForm.payType==='2'"
          label="开户行"
          prop="bankAddress">
        <el-input :class="{'apply-shake': valid.sign}"
                  placeholder="请输入开户行"
                  v-model="ruleForm.bankAddress"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="common-form-btn common-left-space"
                   type="primary"
                   @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import '../../assets/style/myshake.scss'
import constant from "@/constant";
let that=''
const checkAccount =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.account=true
    setTimeout(()=>{
      that.valid.account=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkSign =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.sign=true
    setTimeout(()=>{
      that.valid.sign=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
export default {
  name: "ReceiveMoney",
  components:{},
  data() {
    return {
      payType: constant.payType,
      ruleForm: {
        realName: '',
        aliAccount: '',
        bankAccount: '',
        bankAddress: '',
        payType:'1',
        state:''
      },
      valid:{
        account:false,
        sign:false
      },
      rules: {
        aliAccount:[{validator:checkAccount, trigger: "blur"},],
        realName: [{validator:checkSign, trigger: "blur"},],
        bankAccount:[{validator:checkAccount, trigger: "blur"},],
        bankAddress:[{validator:checkSign, trigger: "blur"},],
      }
    }
  },
  mounted() {
    this.init()
    that=this
  },
  computed: {},
  watch: {},
  methods: {
    init(){
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    checkType(type){
      this.ruleForm.payType=type
    }
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  .img-pay{
    width: 30px;
  }
  .type-pay{
    position: relative;
    top: -6px;
    left: 10px;
  }
  .item-type{
    text-align: center;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    padding: 10px;
  }
  .item-type-active{
    background-color: #E1F3D8;
  }
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
 }
::v-deep .el-tabs__item {
  font-size: 20px;
}
.demo-ruleForm{
  width: 300px;
  margin: 10px auto 0 auto;
}
</style>

<template>
  <div class="content-main-padding">
    <div class="team-title">&nbsp;团队成员</div>
    <el-table
        ref="multipleTable"
        :header-row-style="{'color':'gray'}"
        border
        lazy
        :data="tableData.list"
        style="width: 100%;margin-top: 10px"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        @expand-change="handleExpandChange"
        :row-class-name="tableRowClassName">
      <el-table-column v-if="false"
                       type="selection"
                       width="55">
      </el-table-column>
      <template v-for="(item,index) in columnList">
        <el-table-column :align="item.align"
                         v-if="!item.template"
                         :show-overflow-tooltip="true"
                         :width="item.width"
                         :prop="item.prop"
                         :key="index"
                         :label="item.label">
        </el-table-column>
        <el-table-column :align="item.align"
                         v-else
                         :show-overflow-tooltip="true"
                         :width="item.width"
                         :prop="item.prop"
                         :key="index"
                         :label="item.label">
          <template slot-scope="scope">
            <template v-if="item.params==='control'">
              <el-link class="common-left-space" type="primary" size="mini" @click="">{{$t('table.see')}}</el-link>
            </template>
            <template v-else-if="item.params==='payState'">
              {{scope.row.payState|payStateTransfer()}}
            </template>
            <template v-else-if="item.params==='payType'">
              {{scope.row.payType|payTypeTransfer()}}
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <paging
        :page-sizes="[10,20,25,30,40]"
        :update:page="form.pageNum"
        :update:limit="form.pageSize"
        :total="tableData.total"
        @changeSize="changeSize"
        @pagination="pagination"/>
    <div id="echarts" style="width: 100%;height: 400px;margin-top: 10px"></div>
    <div class="team-title">&nbsp;激励计划</div>
    <div class="stimulate-div">
      <p class="paragraph">“激励计划”是指通过邀请团队成员，按照团队成员的销售额的一定比例提供的收益计划。
        希望通过团队成员的推广，让更多的人获得收益，从而实现团队的盈利。具体的规则如下：</p>
      <p class="paragraph">1. 通过分享自己的推广链接，通过专项推广链接注册的新用户即是自己的团队成员。
        团队成员每售出一个USDT货币，您可以获取1%的收益。平台获取0.1%的收益。</p>
      <p class="paragraph">2.“激励计划”仅针对由推广链接注册的用户，才能获得“激励计划”。不具有推广链接的用户，无法获得“激励计划”。</p>
    </div>
    <div class="earnings-d">
      累计收益:
      <span>1200</span>
      <el-button style="margin-left: 10px"
                 type="primary"
                 size="mini">前往兑现</el-button>
    </div>
  </div>
</template>

<script>
import dictTransfer from "@/mixin/DictTransfer";
import paging from "@/components/Paging";
import constant from "@/constant";
import echarts from "@/mixin/echarts";
export default {
  name: "TeamTable",
  mixins:[dictTransfer],
  components:{paging},
  data() {
    return {
      tableData:{
        list:[
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
          {id:7,account:1060,sellSum:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
        ],
        total:0
      },
      columnList:[
        {align:"center",label:"帐号",prop:"id",width:150,template:false,params:''},
        {align:"center",label:"昵称",prop:"account",width:150,template:false,params:''},
        {align:"center",label:"销售总额",prop:"sellSum",width:100,template:false,params:''},
        {align:"center",label:"帐号创建时间",prop:"createTime",width:180,template:false,params:''},
        {align:"center",label:"上一次交易时间",prop:"payTime",width:'auto',template:false,params:''},
        //{align:"center",label:"订单创建时间",prop:"createTime",width:'auto',template:false,params:''},
        {align:"center",label:"操作",prop:"answer",width:'auto',template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        orderType:'asc'
      },
      ids:[],
      options:constant.payState,
      option:{
        title: {
          text: '团队报表'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['张三', '李四', '赛文', '大炮', '铁柱']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '张三',
            type: 'line',
            stack: '总量',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '李四',
            type: 'line',
            stack: '总量',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '赛文',
            type: 'line',
            stack: '总量',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '大炮',
            type: 'line',
            stack: '总量',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: '铁柱',
            type: 'line',
            stack: '总量',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      },
    }
  },
  mounted() {
    this.init()
    this.initEcharts()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
    },
    initEcharts(){
      let chartDom = document.getElementById('echarts');
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.stimulate-div{
  margin-top: 10px;
  background-color: #c2c4ca;
  border-radius: 10px;
  //height: 30px;
  padding: 10px;
  .paragraph{
    text-indent: 2em;
    font-size: 14px;
    margin: 10px 0;
  }
}
.earnings-d{
  margin-top: 10px;
}
</style>

const t = {
    login: {
        login: 'login',
        cancel: 'cancel',
        username: 'username',
        password: 'password',
        code:'captcha',
        register:'register',

    },
    router: {
        history:'history',
        userinfo:'userinfo',
        marketplace:'marketplace',
        trading:'trading',
        login:'login',
        register:'register',
        admin:'admin',
    }
}
t.add = 'Add'
t.delete = 'Delete'
export default t

<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <el-card shadow="hover" class="box-card">
        <div slot="header">
          <span style="font-weight: bold">用户信息</span>
          <el-link style="float:right;"
                   @click="updateUser"
                   type="primary">
            <i class="el-icon-edit"></i>
            编辑
          </el-link>
        </div>
        <el-descriptions border>
          <!--下面的这个应该消失的 但是没有消失mian.js修改需要重启么-->
          <el-descriptions-item label="帐号">张三565</el-descriptions-item>
          <el-descriptions-item label="昵称">张三565</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家')" label="姓名">张三565</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家')" label="手机号">18100000000</el-descriptions-item>
          <el-descriptions-item label="邮箱地址">123@qq.com</el-descriptions-item>
          <el-descriptions-item label="绑定验证码">
            <el-button size="mini" @click="genOTP" type="primary">绑定验证码</el-button>
          </el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家,超级管理员')" label="汇款地址">wefuvvschjwvfqe</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家,超级管理员')" label="银行卡号">18100000043243242300</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家,超级管理员')" label="USDT转入地址">654645654dfvfdvdb</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家,超级管理员')" label="邀请码">
            <span class="copy-underline"
                  title="点击复制"
                  @click="copyText('43243')">11</span>
          </el-descriptions-item>
          <el-descriptions-item v-if="false" label="备注">
            <el-tag size="small">学校</el-tag>
          </el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('商家,超级管理员')" :label-style="{'background-color': '#E1F3D8', 'text-align': 'left','font-weight': 'bold','color': '#000000'}"
                                :contentStyle="{'background-color': '#E1F3D8', 'text-align': 'left','font-weight': 'bold','color': '#000000'}"
                                label="账户余额" >5543</el-descriptions-item>
        </el-descriptions>
      </el-card>

      <div class="card-list">
        <el-card shadow="hover"
                 v-if="item.auth.includes($store.state.userInfo.roleName)"
                 @click.native="type(item.label)"
                 class="box-card" v-for="(item,index) in columnList">
          <div class="text item">
            <img width="50px" :src="item.icon" loading="eager" alt=""/>
            <div style="margin-top: 10px">{{item.label}}</div>
          </div>
        </el-card>
      </div>
      <el-divider v-if="showTab" content-position="left">{{activeMenu}}</el-divider>
      <!--钱包记录-->
      <WalletRecord v-if="activeMenu === columnList[0].label"/>
      <!--客服-->
      <CustomerService v-else-if="activeMenu ===columnList[5].label"/>
      <!--收款-->
      <ReceiveMoney v-else-if="activeMenu ===columnList[2].label"/>
      <!--团队报表-->
      <TeamTable v-else-if="activeMenu === columnList[3].label"/>
      <!--充值-->
      <TopUp :type="'usdt'" v-else-if="activeMenu === columnList[1].label"/>
      <el-dialog title="使用Authenticator应用扫描二维码"
                 :top="'18%'"
                 :visible.sync="dialogOTP"
                 :modal="false"
                 width="30%">
        <QRCode :text="otpSecret" ref="qrcode2"/>
      </el-dialog>
    </div>
    <div v-else class="content-main-padding">
      <div class="m-user-info">
        <img class="img-info" src="img.png" alt=""/>
        <div class="m-user-info-text">
          <div class="m-user-info-text-name">张三</div>
          <div class="m-user-info-text-phone">
            <span>18100000000</span>
            <span @click="copyText('rr23')" style="float:right;">
              <i class="el-icon-copy-document"></i>
              fjwehfejwq23
            </span>
          </div>
        </div>
      </div>
      <div class="m-money-d">
        <div class="m-money" style="text-align: left;text-indent:1em;">
          账户余额<br>
          <div style="margin-top: 4px;text-align: center">100 LOOM</div>
        </div>
      </div>
      <div class="m-card-list">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item,index) in columnList1">
            <div @click="type(item.label)"
                 class="box-card">
              <div style="width: 60px;text-align: center">
                <img width="30px" :src="item.icon" loading="eager" alt=""/>
                <div style="margin-top: 8px;font-size: 12px">{{item.label}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :span="6" v-for="(item,index) in columnList2">
            <div @click="type(item.label)"
                 class="box-card">
              <div style="width: 60px;text-align: center">
                <img width="30px" :src="item.icon" loading="eager" alt=""/>
                <div style="margin-top: 8px;font-size: 12px">{{item.label}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <SaveOrUpdateUser ref="updateUser"/>
  </div>
</template>

<script>
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser";
import WalletRecord from "@/views/info/WalletRecord";
import CustomerService from "@/views/info/CustomerService";
import TeamTable from "@/views/info/TeamTable";
import ReceiveMoney from "@/views/info/ReceiveMoney";
import common from "@/mixin/common";
import TopUp from "@/views/info/TopUp";
import QRCode from "@/components/QRCode.vue";
import {encryptText} from "@/utils/jsencrypt";
import {onePwdAPI} from "@/api/Login";
import purse from "@/assets/userinfo/purse.svg"
import currency from "@/assets/userinfo/currency.svg"
import setting from "@/assets/userinfo/setting.svg"
import table from "@/assets/userinfo/table.svg"
import pwd from "@/assets/userinfo/pwd.svg"
import user from "@/assets/userinfo/user.svg"
import logout from "@/assets/userinfo/logout.svg"
let that
export default {
  name: "UserInfo",
  mixins:[common],
  components:{QRCode, TopUp, ReceiveMoney, TeamTable, CustomerService, WalletRecord, SaveOrUpdateUser},
  data() {
    return {
      dialogOTP:false,
      otpSecret:'',
      columnList:[
        {label:"钱包记录",path:"id",icon:purse,auth:'商家,超级管理员,消费者'},
        {label:"充值",path:"balance",icon:currency,auth:'商家,超级管理员'},
        {label:"商家设置",path:"payType",icon:setting,auth:'商家,超级管理员'},
        {label:"团队报表",path:"answer",icon:table,auth:'商家,超级管理员'},
        {label:"修改密码",path:"answer",icon:pwd,auth:'商家,超级管理员,消费者'},
        {label:"客服",path:"answer",icon:user,auth:'商家,超级管理员,消费者'},
        {label:"退出登录",path:"answer",icon:logout,auth:'商家,超级管理员,消费者'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        orderType:'asc',
        state: '',
      },
      activeMenu:'钱包记录',
    }
  },
  mounted() {
    this.init()
    that=this
  },
  computed: {
    showTab(){
      return [
          this.columnList[0].label,
          this.columnList[1].label,
          this.columnList[2].label,
          this.columnList[3].label,
          this.columnList[5].label,
      ].includes(this.activeMenu)
    },
    columnList1(){
      return this.columnList.slice(0,4)
    },
    columnList2(){
      return this.columnList.slice(4,this.columnList.length)
    },
  },
  watch: {},
  methods: {
    init(){},
    type(type){
      this.activeMenu=type
      switch (type) {
        case '钱包记录':
          break;
        case 'USDT充值':
          break;
        case '收款设定':
          break;
        case '团队报表':
          break;
        case '修改密码':
          this.$store.dispatch('updatePwd')
          break;
        case '客服':
          break;
        case '退出登录':
          this.$store.dispatch('logout')
          break;
      }
    },
    updateUser(){
      this.$refs.updateUser.form.id=JSON.parse(sessionStorage.getItem('userInfo')).id
      this.$refs.updateUser.info()
    },
    genOTP(){
      let a={password:encryptText('1')}
      onePwdAPI(a).then(res=>{
        setTimeout(()=>{
          this.dialogOTP=true
          this.otpSecret=res.data
          setTimeout(()=>{
            that.$refs.qrcode2.qrcode()
          },200)
        },500)
      })
    },
  },
  filters: {},
  beforeDestroy() {
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
@mixin common-margin{
  margin: 0 10px;
}
.box-card{
  @include common-margin;
  margin-bottom: 10px;
  .my-balance{
    background: #52b71f;
  }
}
.my-balance{
  background: #52b71f;
}
.card-list{
  display: flex;
  text-align: center;
  .box-card{
    cursor: pointer;
    @include common-margin;
    width: 180px;
  }
}

.my-label {
  background: #E1F3D8;
}

.my-content {
  background: #FDE2E2;
}
.el-divider__text, .el-image__error, .el-link, .el-timeline, .el-transfer__button i, .el-transfer__button span{
  font-size: 16px;
}
.m-user-info{
  .img-info{
    height: 50px;
    border-radius: 5px;
  }
  display: flex;
  .m-user-info-text{
    margin-left: 10px;
    width: 100%;
    .m-user-info-text-name{
      font-size: 20px;
    }
    .m-user-info-text-phone{
      margin-top: 8px;
      width: 100%;
      font-size: 14px;
    }
  }
}
.m-money-d{
  border: solid 1px;
  border-radius: 10px;
  height: 80px;
  width: 80%;
  margin: 20px auto 0 auto;
  .m-money{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
  }
}
.m-card-list{
  //display: flex;
  margin-top: 20px;
}
</style>

import request from "@/utils/request";

/**
 * 用户相关
 */
const modulePrefix = 'user'

/**
 * 分页
 */
export function userPageAPI(data) {
    return request({
        url: modulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 详情
 * @param id
 * @returns {*}
 */
export function userInfoAPI(id) {
    return request({
        url: modulePrefix+'/'+id,
        method: 'get',
    })
}

/**
 * 保存
 */
export function userSaveAPI(data) {
    return request({
        url: modulePrefix,
        method: 'post',
        data: data
    })
}

/**
 * 修改
 */
export function userModifyAPI(data) {
    return request({
        url: modulePrefix,
        method: 'put',
        data: data
    })
}

/**
 * 删除
 */
export function userDeleteAPI(idListStr) {
    return request({
        url: modulePrefix+'/batch?id='+idListStr,
        method: 'delete'
    })
}

const twoModulePrefix = '/business'

/**
 * 商家分页
 * @param data
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function businessPageAPI(data) {
    return request({
        url: modulePrefix+twoModulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 商家排序
 * @param id
 * @param sort
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function businessSortAPI(id,sort) {
    return request({
        url: modulePrefix+`/sort/${id}/${sort}`,
        method: 'put',
    })
}



<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div class="header-d">
        <el-button type="primary"
                   style="margin-bottom: 10px"
                   class="common-left-space common-form-btn">
          <i class="el-icon-refresh"></i>
          刷新订单
        </el-button>
        <el-select v-model="form.state"
                   clearable
                   class="common-left-space common-form-btn"
                   placeholder="订单状态">
          <el-option
              v-for="item in options"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-input
            v-if="false"
            @keyup.enter.native="init"
            class="header-d-input"
            v-model="form.name"
            placeholder="根据用户名或者邮箱搜索">
          <el-button @keyup.enter.native="init" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link class="common-left-space" type="danger" size="mini" @click="">{{$t('table.deleted')}}</el-link>
              </template>
              <template v-else-if="item.params==='payState'">
                {{scope.row.payState|payStateTransfer()}}
              </template>
              <template v-else-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else class="content-main-padding">
      <el-button plain>卖币记录</el-button>
      <el-button plain>买币记录</el-button>
      <div class="m-card">
        <el-descriptions title="8月21日" :column="2">
          <el-descriptions-item label="订单金额">118</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag type="success" size="mini">已完成</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="成交数量">1188</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="m-card">
        <el-descriptions title="8月21日" :column="2">
          <el-descriptions-item label="订单金额">118</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag type="danger" size="mini">未完成</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="成交数量">1188</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="m-card">
        <el-descriptions title="8月21日" :column="2">
          <el-descriptions-item label="订单金额">118</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag type="success" size="mini">已完成</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="成交数量">1188</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="m-card">
        <el-descriptions title="8月21日" :column="2">
          <el-descriptions-item label="订单金额">118</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag type="success" size="mini">已完成</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="成交数量">1188</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="m-card">
        <el-descriptions title="8月21日" :column="2">
          <el-descriptions-item label="订单金额">118</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag type="success" size="mini">已完成</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="成交数量">1188</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="m-card">
        <el-descriptions title="8月21日" :column="2">
          <el-descriptions-item label="订单金额">118</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag type="success" size="mini">已完成</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="成交数量">1188</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";

export default {
  name: "History",
  components:{paging},
  mixins:[dictTransfer],
  data() {
    return {
      tableData:{
        list:[
          {id:7,balance:1060,payType:'0',payState:'0',createTime:'2021-01-01 00:00:00',payTime:'2021-01-01 00:00:00'},
        ],
        total:0
      },
      columnList:[
        {align:"center",label:"订单id",prop:"id",width:100,template:false,params:''},
        {align:"center",label:"交易金额",prop:"balance",width:100,template:false,params:''},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType'},
        {align:"center",label:"支付状态",prop:"payState",width:100,template:true,params:'payState'},
        {align:"center",label:"支付时间",prop:"payTime",width:'auto',template:false,params:''},
        {align:"center",label:"付款人",prop:"payTime",width:'auto',template:false,params:''},
        {align:"center",label:"收款人",prop:"payTime",width:'auto',template:false,params:''},
        {align:"center",label:"收款账号",prop:"payTime",width:'auto',template:false,params:''},
        {align:"center",label:"订单创建时间",prop:"createTime",width:'auto',template:false,params:''},
        {align:"center",label:"操作",prop:"answer",width:80,template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        orderType:'asc'
      },
      ids:[],
      options:constant.payState
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){},
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
      this.$refs.saveOrUpdateUser.dialogUser=true
    },
  },
  filters: {

  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  //float: right;
  .header-d-input{
    width: 300px;
    float: right;
    margin-bottom: 10px;
  }
}
.m-card{
  padding: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04),0 -1px 0px rgba(0, 0, 0, .05);
  margin-top: 10px;
}
</style>

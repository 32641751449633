<template>
  <div class="main">
    <!-- pc端头部-->
    <div v-if="$terminal" id="header-d-t" class="header-d">
      <div class="header-inner">
        <span :class="active.includes(item.path)?'menu-text-active':'menu-text'"
              @click="skipRoute(item.path)"
              v-for="(item,index) in menuList">{{item.name}}</span>
      </div>
      <div class="header-right">
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-badge :value="$store.state.eventNum"
                      type="danger">
              <el-avatar shape="square" size="large" :src="img"></el-avatar>
            </el-badge>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$router.push('/userinfo')">用户中心</el-dropdown-item>
            <el-badge :value="$store.state.eventNum" type="danger" class="item">
              <el-dropdown-item @click.native="$router.push('/trading')">查看订单</el-dropdown-item>
            </el-badge>
            <el-dropdown-item @click.native="modifyPwd">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="$store.dispatch('logout')" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="network" :class="spendTime<=200 ?
                            'network-success':spendTime>200 && spendTime<= 500 ?
                            'network-warning':'network-danger'">
        <i class="el-icon-monitor"/>
        {{spendTime!=='网络已断开'?spendTime+'ms':'网络已断开, 请检查网络是否正常'}}
      </div>
    </div>
    <!--pc端内容-->
    <div v-if="$terminal">
      <AdminMenu v-if="isAdmin" class="admin-menu"/>
      <div class="content-main">
        <RouterView class="content"/>
      </div>
      <div v-if="showGoUp" @click="goTop()" class="go-up" title="回到顶部">
        <i class="el-icon-upload2"></i>
      </div>
    </div>
    <!--pc端底部-->
    <div v-if="$terminal" class="footer-d">
      <div class="footer-inner">footer</div>
    </div>

    <!--移动端头部-->
    <div v-if="!$terminal" class="m-header-d">
      <div @click="$router.back()" class="return-back">
        <i class="el-icon-back"></i>返回
      </div>
      <div class="m-title">
        {{getTitle()}}
      </div>
    </div>
    <!--移动端内容-->
    <div v-if="!$terminal">
      <RouterView/>
    </div>
    <!--移动端底部-->
    <div v-if="!$terminal" class="m-footer-d">
      <template v-for="(item,index) in menuList">
        <div class="item-padding" :class="active.includes(item.path)?'item-padding-active':''"
             @click="skipRoute(item.path)">
          <div class="item-margin">
            <i style="font-size: 24px;margin-top: 5px"
               :class="item.icon"></i>
          </div>
          <div style="margin-top: 3px">
            <span style="font-size: 12px">
              {{item.name}}
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import img from "@/assets/userinfo/img.png"
import {getRouteList} from "@/router";
import AdminMenu from "@/components/admin-menu";
import websocket from "@/mixin/websocket";
let timer
export default {
  name: "Layout",
  components:{AdminMenu},
  mixins:[websocket],
  data() {
    return {
      menuList:getRouteList(),
      active: this.$route.path,
      showGoUp: false,
      img:img,
    }
  },
  mounted() {
    this.init()
    let that=this
    timer = setInterval(()=>{
      that.showGoUp=document.documentElement.scrollHeight > window.outerHeight
    },500)
  },
  computed: {
    isAdmin(){
      return this.active.includes('/admin')
    },
  },
  watch: {
    $route(to, from) {
      this.active = to.path
    },
  },
  methods: {
    init(){
    },
    logout(){
      this.$router.push('/login')
    },
    modifyPwd(){
      this.$store.dispatch('updatePwd')
    },
    getTitle(){
      return document.title
    },
    skipRoute(path){
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    goTop(){
      window.scrollTo({top:0,behavior:'smooth'})
    },
  },
  filters: {},
  beforeDestroy() {
    clearInterval(timer)
  }
}
</script>

<style scoped lang="scss">
@import "../assets/layout/header.scss";
$common-width:70%;
$header-height:60px;
$footer-height:60px;
$header-main-footer-space:20px;
@mixin common-center{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.main {
  width: 100vw;
  margin: 0 auto;
  .content-main{
    margin: $header-main-footer-space 0;
    width: $common-width;
    @include common-center;
    .content{
      background-color: white;
      @include common-radius;
      min-height: calc(100vh - $header-height - $header-main-footer-space - $footer-height - $header-main-footer-space);
    }
  }
  .admin-menu{
    position: fixed;
    //150是菜单的宽度
    left: calc(100% - $common-width -  (100% - $common-width) / 2 - 150px);
    z-index: 100;
  }
  .header-d{
    width: 100vw;
    height: $header-height;
    background-color: white;
    display: flex;
    //padding: 10px;
    .header-inner{
      width: $common-width;
      @include common-center;
      text-align: center;
      font-size: 24px;
      .menu-text{
        margin: 0 60px;
        cursor: pointer;
        line-height: 58px;
      }
      .menu-text:hover{
        margin: 0 45px;
        cursor: pointer;
        line-height: 58px;
        background-color: #C6E2FF;
        padding: 2px 15px;
        border-radius: 5px;
      }
      .menu-text-active{
        margin: 0 30px;
        background-color: #79BBFF;
        padding: 3px 15px;
        border-radius: 5px;
        cursor: pointer;
        line-height: 58px;
      }
    }
    .header-right{
      cursor: pointer;
      position: absolute;
      //60 是头像的宽度
      left: calc(100% - (100% - $common-width) / 2 - 60px);
      top: 8px;
    }
  }
  .footer-d{
    height: $footer-height;
    background-color: white;
    .footer-inner{
      width: $common-width;
      @include common-center;
    }
  }
  .go-up{
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    //border: solid 1px ;
    background: white;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    cursor: pointer;
    bottom: calc($footer-height * 2);
    // $common-width 是内容的宽度 (100% - $common-width) / 2 是两边的留白的段度 40px是方块的宽度
    right: calc(100% - $common-width - (100% - $common-width) / 2 - 40px - 40px);
  }

  .m-header-d{
    height: $header-height;
    //盒子下方阴影
    box-shadow: 0 1px 2px rgba(195, 182, 182, 0.5);
    .return-back{
      font-weight: bold;
      position: relative;
      top:33%;
      transition: top;
    }
    .m-title{
      text-align: center;
    }
  }
  .m-footer-d{
    height: $footer-height;
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    //盒子上方阴影
    box-shadow: 0 -1px 2px rgba(195, 182, 182, 0.5);
    .item-margin{
      text-align: center;
    }
    .item-padding{
      flex: 1;
      //border: solid 1px;
      //padding-top: 5px;
      padding: 0 15px;
      background-color: #ffffff;
      text-align: center;
    }
    .item-padding-active{
      color: #79BBFF;
    }
  }
}
.network{
  position: absolute;
  left: 10px;
  top: 18px;
  cursor: pointer;
}
.network-success{
  color: #67C23A;
}
.network-warning{
  color: #E6A23C;
}
.network-danger{
  color: #F56C6C;
}
.item {
  //margin-top: 10px;
  margin-right: 20px;
}
</style>

const t = {
    login: {
        account: '帐号',
        email: '邮箱',
        login: '登录',
        register: '注册',
        cancel: '取消',
        nickName: '昵称',
        password: '密码',
        code: '验证码',
        registerCode: '邀请码(选填)',
        forgotPassword:'忘记密码',
        otpNum:'随机验证码',
        return:'返回',
    },
    router: {
        history: '历史',
        userinfo: '我的',
        marketplace: '市场',
        trading:'交易',
        login: '登录',
        register: '注册',
        admin:'管理',
        adminUser:'用户管理',
        adminBusiness:'商家管理',
        adminSetting:'设置',
        businessList:'订单管理',
        notFound: '页面走丢了',

    },
    table:{
        shoppingBtn:'购买',
        modify: '修改',
        deleted:'删除',
        see:'详情',
        seeBusiness:'交易记录',
        approveOk:'通过',
        shield: '拉黑',
        goPay:'去支付',
        payed:'已支付',
        cancelPay:'取消订单',
        closeOrder:'订单完成',
        returnMoney:'退回订金',
        sort: '修改排名',
        resetPwd:'重置密码',
        lockOrder:'锁定订单',
    },
    userInfo:{

    },
}
t.add = '新增'
t.delete = '删除'
export default t

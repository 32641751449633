<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :small="componentSize"
      :background="background"
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :layout="layout"
      :pager-count="pagerCount"
      :total="Number(total)"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'Paging',
  props: {
    componentSize:{
      required:false,
      type:Boolean,
      default:false
    },
    pageSizeShow:{
      required: false,
      default:true,
    },
    total: {
      required: false,
      type: [Number,String],
      default:20
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default:[15, 20, 25,30]
    },
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7
    },
    layout: {
      type: String,
      default: 'total,sizes,prev, pager, next,jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1
      }
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        //scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('changeSize', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        //scrollTo(0, 800)
      }
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 20px 0 16px 16px;
  text-align: end;
}

.pagination-container.hidden {
  display: none;
}

</style>

import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1LPXyoW1I6Rl4V7mXuMMW7xMJeuTs3RwKQJ0wKQGLHvHF+Vrhq71ulbWMxBSCSOBsERa7UHfwOW+SV4csiaeaFHnMtcRja/9V601JW4JTVBs1ibg4SEfoPmMetwDEvO2oapYF27QQeEUCeYAMvUDqdKOsClDjdrlV5WWa5PEBj8+UF4f/47r5aXIapwLRX0zB5VcLmNui/ImIaq9IoWY4cyjuQf+XfwEDk/e1jkotyPlp9Eh8brbHP+R21rK7Amvhqn/CwkfwIPIdCzImhfM8BQY7rv4+CH4dIypISCVFdaP3220g5wbtz+w5Z+rLvQWf6nI+zUMRGYz6SeeyowLIQIDAQAB'

const privateKey = ''

// 加密
export function encryptText(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
function decryptText(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}


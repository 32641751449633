<template>
  <el-dialog
      :top="'2%'"
      :title="form.id===''? '创建用户':disabled?'详细信息':'修改信息'"
      :modal="true"
      :append-to-body="true"
      @close="resetForm('ruleForm')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogUser">
    <el-form :model="form"
             :rules="rules"
             :disabled="disabled"
             ref="ruleForm"
             label-width="140px"
             class="demo-ruleForm">
      <div class="form-div">
        <!--1-->
        <div class="form-col">
          <el-form-item label="帐号"
                        prop="account">
            <el-input clearable
                      @blur="checkAccount"
                      :disabled="form.id!==''"
                      v-model="form.account"></el-input>
          </el-form-item>
        </div>
        <div class="form-col">
          <el-form-item label="昵称"
                        prop="nickName">
            <el-input clearable v-model="form.nickName"></el-input>
          </el-form-item>
        </div>
        <!--2-->
        <div class="form-col" v-role="'超级管理员'" >
          <el-form-item label="角色"
                        prop="roleId">
            <el-select style="width: 100%" v-model="form.roleId" placeholder="请选择">
              <el-option
                  v-for="item in roleList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-col">
          <el-form-item label="令牌"
                        prop="otpSecret">
            <el-button type="danger"
                       @click="resetOTP"
                       size="small">重新生成</el-button>
            <el-button :disabled="false"
                       type="success"
                       @click="showOTP"
                       size="small">添加到手机</el-button>
            <el-button type="primary"
                       @click="genOTP"
                       size="small">将令牌添加到手机</el-button>
          </el-form-item>
        </div>
        <!--3-->
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer" >
          <el-form-item label="支付宝" prop="aliAccount">
            <el-input show-word-limit
                      maxlength="11"
                      minlength="11"
                      clearable v-model="form.aliAccount"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer" >
          <el-form-item label="真实姓名" prop="realName">
            <el-input clearable v-model="form.realName"></el-input>
          </el-form-item>
        </div>
        <!--4-->
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer">
          <el-form-item label="银行卡号"
                        prop="bankAccount">
            <el-input clearable show-word-limit maxlength="18" v-model="form.bankAccount"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer">
          <el-form-item label="开户行" prop="bankAddress">
            <el-input clearable v-model="form.bankAddress"></el-input>
          </el-form-item>
        </div>
        <!--5-->
        <div class="form-col">
          <el-form-item label="邮箱" prop="email">
            <el-input clearable v-model="form.email"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer" >
          <el-form-item v-if="false" label="汇款地址"
                        prop="remittanceAddress">
            <el-input clearable v-model="form.remittanceAddress"></el-input>
          </el-form-item>
          <el-form-item label="备注"
                        prop="remark">
            <el-input clearable v-model="form.remark"></el-input>
          </el-form-item>
        </div>
        <!--6-->
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer" >
          <el-form-item label="usdt余额" prop="usdtBalance">
            <el-input clearable v-model.number="form.usdtBalance"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'超级管理员'" >
          <el-form-item v-if="false" label="人民币余额" prop="rmbBalance">
            <el-input clearable v-model.number="form.rmbBalance"></el-input>
          </el-form-item>
          <el-form-item label="随机验证码">
            <el-radio-group v-model="form.openVerify">
              <el-radio v-for="item in openVerifyList"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!--7-->
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer" >
          <el-form-item label="商户状态" prop="businessState">
            <el-radio-group v-model="form.businessState">
              <el-radio v-for="item in businessState"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'商家,超级管理员'" v-if="!isConsumer" >
          <el-form-item label="支付方式"
                        prop="payType">
            <el-radio-group v-model="form.payType">
              <el-radio v-for="item in payType"
                        v-if="!item.disabled"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!--8-->
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer">
          <el-form-item label="帐户状态"
                        prop="userState">
            <el-select style="width: 100%" v-model="form.userState" placeholder="请选择">
              <el-option
                  v-for="item in userState"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer">
          <el-form-item label="平台计算开关">
            <el-radio-group v-model="form.transactionRateOpen">
              <el-radio v-for="item in openVerifyList"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!--9-->
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer" >
          <el-form-item label="手续费" prop="name">
            <el-input-number style="width: 100%" :min="0" :max="20000"
                             v-model="form.handlingCharge"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'超级管理员,商家'" v-if="!isConsumer" >
          <el-form-item label="下级提成比例" prop="name">
            <el-input-number style="width: 100%" :min="0" :max="20000"
                             v-model="form.handlingCharge"></el-input-number>
          </el-form-item>
        </div>
        <!--10-->
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer" >
          <el-form-item label="支付宝最低支付金额" prop="name">
            <el-input-number style="width: 100%" :min="500" :max="20000"
                             v-model="form.aliMinLimit"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer" >
          <el-form-item label="支付宝最高支付金额" prop="name">
            <el-input-number style="width: 100%" :min="500" :max="20000"
                             v-model="form.aliMaxLimit"></el-input-number>
          </el-form-item>
        </div>
        <!--11-->
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer" >
          <el-form-item label="银行卡最低支付金额" prop="name">
            <el-input-number style="width: 100%" v-model="form.bankMinLimit"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'超级管理员'" v-if="!isConsumer" >
          <el-form-item label="银行卡最高支付金额" prop="name">
            <el-input-number style="width: 100%" v-model="form.bankMaxLimit"></el-input-number>
          </el-form-item>
        </div>
        <!--12-->
        <div style="width: 100%">
          <el-button type="primary"
                     @click="submitForm('ruleForm')"
                     class="common-form-btn common-left-space">保存</el-button>
          <el-button type="info"
                     :disabled="false"
                     @click="resetForm('ruleForm')"
                     class="common-form-btn">取消</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog title="使用Authenticator应用扫描二维码"
               :top="'18%'"
               :visible.sync="dialogOTP"
               :modal="false"
               width="20%">
      <QRCode :text="otpSecret" ref="qrcode"/>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {Message, MessageBox} from "element-ui";

const validateNumber =  (rule, value, callback)=>{
  if (value === undefined || value === '') {
    callback();
    return
  }
  if (!/^\d+$/.test(value)) {
    callback(new Error('请输入有效的数字'));
  } else {
    callback();
  }
}
import constant from "@/constant";
import {encryptText} from "@/utils/jsencrypt";
import {dynamicAPI, existAPI, modifyPwdAPI, onePwdAPI, resetDynamicAPI, verifyPwdAPI} from "@/api/Login";
// import QRcode from "qrcodejs2";
import QRCode from "@/components/QRCode.vue";
import common from "@/mixin/common";
import {userInfoAPI, userModifyAPI, userSaveAPI} from "@/api/user";
import userInfo from "@/views/info/UserInfo.vue";
let qrcode = null;
let that=null
export default {
  name: "SaveOrUpdateUser",
  components: {QRCode},
  mixins:[common],
  props:{},
  data(){
    return{
      dialogOTP: false,
      dialogUser: false,
      disabled:false,
      payType:constant.payType,
      businessState:constant.businessState,
      userState:constant.userState,
      roleList:constant.roleList,
      openVerifyList:constant.phoneVerify,
      form:{
        openVerify:0,
        roleId: 2,
        pageNum:1,
        pageSize:10,
        orderType:'asc',
        account:'',
        aliAccount: '',
        email: '',
        nickName: '',
        realName: '',
        bankAccount: '',
        bankAddress: '',
        remittanceAddress: '',
        rmbBalance: '',
        //邀请码
        inviteCode: '',
        remark: '',
        usdtBalance: '',
        businessState: 1,
        payType: 1,
        id: '',
        userState:1,
        otpSecret: '',
        //商家汇率
        transactionRate:0,
        //商家汇率计算 开关
        transactionRateOpen:0,
        //手续费
        handlingCharge:0,
        //支付宝最大限额
        aliMaxLimit:0,
        //支付宝最小限额
        aliMinLimit:0,
        //银行最大限额
        bankMaxLimit:0,
        //银行最小限额
        bankMinLimit:0,
        //下级提成比例
        juniorRate:0,
      },
      rules: {
        account: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur' }
        ],
        aliAccount:[
          { required: false, message: '必填项', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号' }
        ],
        email: [
          { required: true, message: '必填项', trigger: 'blur' },
          { type: 'email', message: '请输入有效的邮箱地址', trigger: ['blur', 'change'] }
        ],
        remittanceAddress: [

        ],
        bankAccount: [
          { required: false, message: '必填项', trigger: 'blur' },
          { max:18,min:13, message: '请输入有效的银行卡号',trigger: ['blur', 'change'] }
        ],
        usdtBalance: [
          { validator: validateNumber, trigger: 'blur' }
        ],
      },
      otpSecret: '',
    }
  },
  mounted() {
    that=this
    this.init()
  },
  computed: {
    isConsumer(){
      return this.form.roleId===constant.roleMap.consumer || this.form.roleId===constant.roleMap.api
    }
  },
  watch: {},
  methods: {
    init(){},
    info(){
      userInfoAPI(this.form.id).then(res=>{
        if (res.code === 200) {
          this.form=res.data
          this.form.roleId=this.$store.state.userInfo.roleId
          this.dialogUser=true
        }
      })
    },
    genOTP(){
      let a={password:encryptText('1')}
      onePwdAPI(a).then(res=>{
        this.form.otpSecret=res.data.split('=')[1]
        setTimeout(()=>{
          this.dialogOTP=true
          this.otpSecret=res.data
          setTimeout(()=>{
            that.$refs.qrcode.qrcode()
          },200)
        },500)
      })
    },
    resetOTP(){
      MessageBox.prompt('敏感操作, 请先验证密码', '重置手机令牌', {
        distinguishCancelAndClose: true, // 点击取消和关闭按钮会返回不同的值
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入密码', // 输入框的占位符
        inputPattern: /^[0-9a-zA-Z]{6,20}$/, // 输入框的正则表达式校验
        inputErrorMessage: '密码必须包含数字、字母和特殊字符，长度在6-20位之间' // 输入校验失败时的提示信息
      }).then(({value}) => {
        let form={
          id:JSON.parse(sessionStorage.getItem('userInfo')).id,
          password:encryptText(value)
        };
        verifyPwdAPI(form).then(res=>{
          if (res.data) {
            Message.success('验证成功');
            resetDynamicAPI().then(res=>{
              if (res.code === 200) {
                Message.success('重置成功');
                this.dialogOTP=true
                this.otpSecret=res.data
                setTimeout(()=>{
                  that.$refs.qrcode.qrcode()
                },200)
              }
            })
          } else {
            Message.error('密码错误')
          }
        })
      }).catch(() => {
      });
    },
    showOTP(){
      dynamicAPI().then(res=>{
        if (res.code === 200) {
          this.dialogOTP=true
          this.otpSecret=res.data
          setTimeout(()=>{
            that.$refs.qrcode.qrcode()
          },200)
        }
      })
    },
    submitForm(formName) {
      this.form.id===''?'':this.rules.account[1].min=1
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            //修改
            userModifyAPI(this.form).then(res=>{
              this.popUp(res)
              this.dialogUser=false
              this.$emit('reload')
            })
          } else {
            //保存
            userSaveAPI(this.form).then(res=>{
              this.popUp(res)
              this.dialogUser=false
              this.$emit('reload')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogUser=false
      this.disabled=false
    },
    checkAccount(){
      const {account}=this.form
      if (account) {
        existAPI({account}).then(res=>{
          this.popUpError(res)
          if (res.code === 200) {
            if (res.data === false) {
              this.ok('帐号未重复')
            } else {
              this.form.account=''
              this.error('帐号重复, 请修改后再添加')
            }
          }
        });
      }
    },
  },
  filters: {},
  beforeDestroy() {
    qrcode=null
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.form-div{
  display: flex;
  //width: 800px;
  flex-wrap: wrap; /* 允许子元素换行 */
  .form-col{
    //width: 400px;
    width: 50%; /* 子元素宽度为父容器的一半 */
    box-sizing: border-box; /* 包括padding和border在内的宽度计算 */
    //padding: 10px;
  }
}
::v-deep .el-form-item__label {
  font-weight: bold;
}
</style>

let interval = null;
export default {
  data() {
    return {
      url: "ws",
      websocket: null,
      wsStatus: 0,
      statsList: [
        {'code': 0, 'msg': '连接尚未建立'},
        {'code': 1, 'msg': '连接已建立可以进行通信'},
        {'code': 2, 'msg': '连接正在进行关闭'},
        {'code': 3, 'msg': '连接已经关闭或者连接不能打开'},
      ],
      pingRate: 5,
      spendTime: 0,
      currentTime:new Date().getTime()
    }
  },
  created() {
  },
  mounted() {
    this.createWebSocket()
  },
  unmounted() {
  },
  methods: {
    createWebSocket() {
      const token = sessionStorage.getItem('token');
      if (token) {
        this.websocket = new WebSocket(this.url,[token]);
        this.websocket.onmessage = this.message
        this.websocket.onopen = this.open
        this.websocket.onerror = this.error
        this.websocket.onclose = this.close
      }
    },
    open(e) {
      this.$message.success({message: '连接成功',showClose:true})
      this.ping()
    },
    message(e) {
      this.currentTime=new Date().getTime()
      if (e.data.charAt(0) === 't') {
        const a = this.currentTime-Number(e.data.substring(1))-this.pingRate*1000
        if (a > 0) {
          this.spendTime=a
          console.log('网络延时',this.spendTime);
        } else {
          this.spendTime=this.currentTime-Number(e.data.substring(1))
        }
      } else if (e.data.charAt(0) === '{') {
        const message = JSON.parse(e.data);
        if (message.messageType === 0) {
          this.chat(message)
        } else if (message.messageType === 1) {
        } else if (message.messageType >= 2) {
          this.order(message)
        }
        switch (message.messageType) {
          //聊天
          case 0:
            break
          case 1:
            this.$store.dispatch('audioNewOrder')
            this.$bus.$emit('refreshOrder')
            break
          //订单
          case 2:
            this.$store.dispatch('audioMoney')
            this.$bus.$emit('refreshOrder')
            break
          //提醒商家
          case 3:
            this.$store.dispatch('audioMoney')
            this.$bus.$emit('refreshOrder')
            break
        }
      }
      interval= setTimeout(()=>{
        this.ping()
        clearTimeout(interval)
      },this.pingRate*1000);
    },
    error(e) {
      console.error('连接失败',e)
    },
    close(e) {
      console.warn(e)
      this.spendTime='网络已断开'
    },
    ping() {
      const time='t'+this.currentTime
      this.websocket.send(time);
    },
    sendMsg(content){
      this.currentTime=new Date().getTime()
      this.websocket.send(content);
    },
    order(){
    },
    chat(){},
  },
  beforeDestroy() {
    clearTimeout(interval);
    this.websocket.close()
    console.log('websocket close')
  }
}

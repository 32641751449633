import axios from 'axios'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import {Encrypt} from "@/utils/CryptoJS";
import {encryptText} from "@/utils/jsencrypt";
import router from "@/router";
// 创建axios实例
const baseUrl = process.env.VUE_APP_BASE_API
const service = axios.create({
    baseURL: baseUrl, // api 的 base_url
    timeout: 6000, // 请求超时时间
})
let loadingInstance
// request拦截器
service.interceptors.request.use(config => {
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    //todo 判断当前的请求路径 如果是登录接口 而且此时已经有token的，不调接口 直接登录
    if (config.url === 'login') {
        if (sessionStorage.getItem('token')) {
            // router.push('/marketplace')
            Message.success({message:'登录成功',showClose:true})
            return
        }
    }
    if (sessionStorage.getItem('token')) {
        config.headers = {
            'token': sessionStorage.getItem('token'),
            'expiretime': sessionStorage.getItem('expireTime')
        };
    }
    const time = String(new Date().getTime())
    const timeAes = Encrypt(time)
    const timeRsa = encryptText(time)
    config.headers = {
        'sec1': timeAes,
        //'sec2':timeRsa,
        ...config.headers
    }
    loadingInstance = Loading.service({
        fullscreen: true,
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0)'
    });
    return config
}, error => {
    loadingInstance.close()
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        loadingInstance.close()
        if (res.headers.token !== undefined) {
            sessionStorage.setItem('token', res.headers.token)
        }
        if (res.headers.expiretime !== undefined) {
            sessionStorage.setItem('expireTime', res.headers.expiretime)
        }
        if (res.data.code >= 200 ||
            res.status === 500) {
            //401 没有登录 403没有权限
            const code = res.data.code
            if (code >=400 ) {
                Message({
                    message: res.data.msg,
                    type: 'error',
                    duration: 1000,
                    showClose:true
                });
            } else {

            }
            switch (code) {
                case 401:
                    removeInfo();
                    if (router.currentRoute.path !== '/') {
                        router.push('/')
                    }
                    break;
                case 403:
                    break;
            }
            return res.data;
        }
        return res.data;
    },
    error => {
        loadingInstance.close()
        let {response} = error;
        if (error.message.includes('Cannot read')) {
            router.push(router.getRoutes()[2].path)
            return
        }
        if (response.data.msg) {
            Message({
                message: response.data.msg,
                type: 'error',
                duration: 2 * 1000
            });
        }
        return response.data
    }
)
export default service

export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

function setInfo(headers) {

}

function removeInfo() {
    sessionStorage.clear()
}

import request from "@/utils/request";

/**
 * 订单相关
 * @type {string}
 */
const modulePrefix = 'order'

/**
 * 分页
 */
export function orderPageAPI(data) {
    return request({
        url: modulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 保存
 */
export function orderSaveAPI(data) {
    return request({
        url: modulePrefix,
        method: 'post',
        data: data
    })
}

/**
 * 修改
 */
export function orderModifyAPI(data) {
    return request({
        url: modulePrefix,
        method: 'put',
        data: data
    })
}

/**
 * 删除
 */
export function orderDeleteAPI(idListStr) {
    return request({
        url: modulePrefix+'/batch?id='+idListStr,
        method: 'delete'
    })
}
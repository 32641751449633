<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <el-button type="primary"
                 @click="init()"
                 style="float:right;margin-bottom: 10px">
        <i class="el-icon-refresh"></i>
        刷新订单
      </el-button>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         align="center"
                         width="40">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link class="common-left-space" v-if="scope.row.state!==8" type="info" size="mini" @click="controls('see',scope.row)">{{$t('table.see')}}</el-link>
                <el-link class="common-left-space" v-role="'消费者'" v-if="scope.row.state!==8 && scope.row.state!==2" type="primary" size="mini" @click="controls('payed',scope.row)">{{$t('table.payed')}}</el-link>
                <el-link class="common-left-space" v-role="'消费者'" v-if="scope.row.state===2 || (!isConsumer)" type="success" size="mini" @click="controls('warn',scope.row)">提醒卖家</el-link>
                <el-link class="common-left-space" v-role="'商家'" v-if="scope.row.state!==8" type="success" size="mini" @click="controls('closeOrder',scope.row)">{{$t('table.closeOrder')}}</el-link>
                <el-link class="common-left-space" v-role="'超级管理员'" v-if="scope.row.state!==8" type="success" size="mini" @click="controls('closeOrder',scope.row)">{{$t('table.closeOrder')}}</el-link>
                <el-link class="common-left-space" v-role="'商家'" v-if="scope.row.state!==8" type="danger" size="mini" @click="controls('cancelPay',scope.row)">{{$t('table.cancelPay')}}</el-link>
                <el-link class="common-left-space" v-role="'超级管理员'" v-if="scope.row.state!==8" type="danger" size="mini" @click="controls('cancelPay',scope.row)">{{$t('table.cancelPay')}}</el-link>
                <el-link class="common-left-space" v-if="scope.row.state===8" type="primary" size="mini" @click="controls('lockOrder',scope.row)">{{$t('table.lockOrder')}}</el-link>
              </template>
              <template v-else-if="item.params==='payState'">
                <el-tag size="mini">{{scope.row.state|payStateTransfer()}}</el-tag>
              </template>
              <template v-else-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
              <template v-else-if="item.params==='usdtCount'">
                <el-input-number size="mini"
                                 :min="1"
                                 v-model="scope.row.uCount">
                </el-input-number>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else class="content-main-padding">
      trading
    </div>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import constant from "@/constant";
import dictTransfer from "@/mixin/DictTransfer";
import {orderModifyAPI, orderPageAPI} from "@/api/order";
import common from "@/mixin/common";
export default {
  name: "Trading",
  components:{paging},
  mixins:[dictTransfer,common],
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"订单id",prop:"id",width:100,template:false,params:'',show:true},
        {align:"center",label:"支付状态",prop:"payState",width:100,template:true,params:'payState',show:true},
        {align:"center",label:"usdt余额",prop:"usdtBalance",width:'auto',template:false,params:'',show:true},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType',show:true},
        {align:"center",label:"帐号",prop:"account",width:'auto',template:false,params:'account',show:true},
        {align:"center",label:"帐号验证",prop:"address",width:'auto',template:false,params:'address',show:true},
        {align:"center",label:"创建时间",prop:"createTime",width:'auto',template:false,params:'',show:true},
        {align:"center",label:"交易金额",prop:"balance",width:100,template:false,params:'',show:true},
        {align:"center",label:"购买数量",prop:"uCount",width:180,template:true,params:'usdtCount',show:true},
        {align:"center",label:"操作",prop:"answer",width:220,template:true,params:'control',show:true},
      ],
      form:{
        pageNum:1,
        pageSize:10,
      },
      ids:[],
      options:constant.payState
    }
  },
  mounted() {
    this.init()
    this.$bus.$on('refreshOrder',()=>{
      this.init()
    })
  },
  updated() {

  },
  computed: {
    isConsumer(){
      return JSON.parse(sessionStorage.getItem('userInfo')).roleName === '消费者'
    }
  },
  watch: {},
  methods: {
    init(){
      const roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName;
      if (roleName === '消费者') {
        this.form.state='1,2,8'
      } else if (roleName === '商家') {
        this.form.state='1,2'
      } else {
        this.form.state='1,2,8'
      }
      orderPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
          this.$store.state.eventNum=res.data.total
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
    },
    controls(type,params){
      let form = {
        id:params.id,
        state:constant.payState[0].type,
        payType:params.payType,
        userId:JSON.parse(sessionStorage.getItem('userInfo')).id,
        businessId:params.businessId,
        orderVersion:params.orderVersion,
      }
      switch (type) {
        case 'cancelPay':
          form.state=constant.payState[7].type
          form.messageType=constant.payState[7].type
          this.modify(form)
          break;
        case 'closeOrder':
          form.state=constant.payState[10].type
          form.messageType=constant.payState[10].type
          this.modify(form)
          break;
        case 'see':
          break;
        case 'payed':
          form.state=constant.payState[2].type;
          form.messageType=constant.payState[2].type;
          this.modify(form)
          break;
        case 'lockOrder':
          console.log(params)
          if (params.uCount) {
            form.state=constant.payState[1].type
            form.messageType=constant.payState[1].type
            form.uCount=String(params.uCount)
            this.modify(form)
          } else {
            this.error('购买数量不少于0')
          }
          break;
        case 'warn':
          form.businessId=params.businessId
          form.messageType=constant.messageType[3].type
          form.state=constant.payState[9].type
          this.modify(form,'warn')
          break;
      }
    },
    modify(form,type){
      orderModifyAPI(form).then(res=>{
        this.popUp(res)
        if (type) {
        } else {
          this.init()
        }
      });
    },
  },
  filters: {

  },
  beforeDestroy() {
    this.$bus.$off('refreshOrder')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
    margin-bottom: 10px;
  }
}
</style>

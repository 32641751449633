//https://echarts.apache.org/v4/zh/tutorial.html
let echarts = require('echarts/lib/echarts')
// 系列类型文档速查
// require('echarts/lib/chart/bar')			// 柱状图
// require('echarts/lib/chart/pie')			// 饼图
require('echarts/lib/chart/line')			// 折线图
// 组件文档速查
require('echarts/lib/component/tooltip')  // 标题
require('echarts/lib/component/title')  // 提示栏
require('echarts/lib/component/legend')  // 图例
require('echarts/lib/component/markPoint')   // 标注
require('echarts/lib/component/dataZoom')  // 数据区域缩放
// https://echarts.apache.org/zh/cheat-sheet.html  引入时的参考网址
export default echarts

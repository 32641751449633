<template>
  <div>
    <div v-if="type === 'money'">
      <h2 class="m-title">请将钱款转入到&nbsp;
        <span style="color: #79BBFF">{{form.payType|payTypeTransfer}}</span>
        &nbsp;收款账号</h2>

      <h2 v-if="form.payType==='1'" class="m-money-d-d">
        <span class="money-label">{{form.payType|payTypeTransfer}}</span>：{{form.aliAccount}}
      </h2>
      <h2 v-else class="m-money-d-d">
        <span class="money-label">{{form.payType|payTypeTransfer}}</span>：{{form.bankAccount}}
      </h2>

      <h2 v-if="form.payType==='1'" class="m-money-d-d">
        <span class="money-label">姓名</span>：{{form.realName}}
      </h2>
      <h2 v-else class="m-money-d-d">
        <span class="money-label" >开户行</span>：{{form.bankAddress}}
      </h2>
    </div>
    <div v-else>
      <div class="m-title">请将货币转入到我们的中间担保帐号</div>
      <div style="margin-top: 20px;"
           @click="copyText('58493780fdifhweiofyy29rqy8')"
           class="copy-underline">58493780fdifhweiofyy29rqy8</div>
    </div>
  </div>
</template>

<script>
import dictTransfer from "@/mixin/DictTransfer.js";
import constant from "@/constant";
import common from "@/mixin/common";
export default {
  name: "TopUp",
  mixins:[dictTransfer,common],
  props:{
    form:{
      required:false,
      type:Object,
      default:{
        payType: '2',
        aliAccount: '184328432',
        realName:'张三',
        bankAddress:'wqasa',
        bankAccount:'wq',
      }
    },
    type:{
      required: false,
      type:String,
      default:'money'
    }
  },
  data() {
    return {
      payType:constant.payType,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.m-money-d-d{
  margin-top: 20px;
}
.money-label{
  font-weight: bold;
}
</style>

<template>
  <div>
    <div v-if="$terminal">
      <div class="header-d">
        <el-button :disabled="ids.length<=1" @click="batchDelete" type="danger">
          <i class="el-icon-delete"/>
          批量删除
        </el-button>
        <el-button @click="saveOrUpdate" type="primary">
          创建用户
        </el-button>
        <el-select v-model="form.userState"
                   clearable
                   @change="init"
                   class="common-left-space"
                   placeholder="用户状态">
          <el-option
              v-for="item in options"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-select v-model="form.roleId"
                   clearable
                   @change="init"
                   class="common-left-space"
                   placeholder="用户类别">
          <el-option
              v-for="item in roleList"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-button class="common-left-space"
                   @click="form.account='';form.userState='';form.roleId='';init()"
                   type="info">
          <i class="el-icon-refresh"></i>
          重置搜索
        </el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            class="header-d-input"
            v-model="form.account"
            placeholder="支持帐号、昵称、姓名模糊搜索">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         align="center"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="info" size="mini" @click="controls('see',scope.row)">{{$t('table.see')}}</el-link>
                <el-link class="common-left-space" type="primary" size="mini" @click="controls('modify',scope.row)">{{$t('table.modify')}}</el-link>
                <el-link class="common-left-space" v-if="scope.row.userState===0" type="success" size="mini" @click="controls('approveOk',scope.row)">{{$t('table.approveOk')}}</el-link>
                <el-link class="common-left-space" type="warning" size="mini" @click="controls('resetPwd',scope.row)">{{$t('table.resetPwd')}}</el-link>
                <el-link class="common-left-space" type="danger" size="mini" @click="controls('deleted',scope.row)">{{$t('table.deleted')}}</el-link>
              </template>
              <template v-if="item.params==='businessState'">
                {{scope.row.businessState|businessStateTransfer}}
              </template>
              <template v-if="item.params==='userState'">
                {{scope.row.userState|userStateTransfer}}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else>
      user
    </div>
    <SaveOrUpdateUser @reload="init" ref="saveOrUpdateUser"/>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser";
import constant from "@/constant";
import {userDeleteAPI, userPageAPI} from "@/api/user";
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import {controlAPI} from "@/api/Login";
export default {
  name: "User",
  mixins:[common,dictTransfer],
  components:{SaveOrUpdateUser, paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"帐号",prop:"account",width:150,template:false,params:''},
        {align:"center",label:"真实姓名",prop:"realName",width:100,template:false,params:''},
        {align:"center",label:"昵称",prop:"nickName",width:150,template:false,params:''},
        {align:"center",label:"角色",prop:"roleName",width:100,template:false,params:''},
        {align:"center",label:"商户状态",prop:"businessState",width:80,template:true,params:'businessState'},
        /*{align:"center",label:"USDT地址",prop:"usdtAddress",width:100,template:false,params:''},*/
        {align:"center",label:"USDT余额",prop:"usdtBalance",width:100,template:false,params:''},
        {align:"center",label:"状态",prop:"userState",width:100,template:true,params:'userState'},
        {align:"center",label:"操作",prop:"answer",width:'auto',template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        userState: '',
        account: '',
        roleId: '',
      },
      ids:[],
      options:constant.userState,
      roleList:constant.roleList,
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      userPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
      this.$refs.saveOrUpdateUser.dialogUser=true
    },
    controls(type,params){
      switch (type) {
        //查看详情
        case 'see':
            this.$refs.saveOrUpdateUser.disabled = true
        //修改
        case 'modify':
          const userInfo = JSON.parse(JSON.stringify(params))
          this.$refs.saveOrUpdateUser.form = userInfo
          this.$refs.saveOrUpdateUser.dialogUser = true
          break
        //通过注册申请
        case 'approveOk':
          controlAPI(0,params.id).then(res=>{
            this.popUp(res)
            this.init()
          })
          break
        //重置密码
        case 'resetPwd':
          controlAPI(1,params.id).then(res=>{
            this.popUp(res)
          })
          break
        //删除
        case 'deleted':
          this.ids.push(params.id)
            this.batchDelete()
          break
      }
    },
    batchDelete(){
      userDeleteAPI(this.ids).then(res=>{
        this.ids=[]
        this.popUp(res)
        this.init()
      })
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>

<template>
  <div class="qrcode">
    <div class="qrcode_content" id="qrCode"></div>
  </div>
</template>

<script>
//npm i qrcodejs2
import QRcode from "qrcodejs2";
let qrcode = null;
export default {
  name: "QRCode",
  props: {
    text: {
      type: String,
      default: ''
    },
    sWidth: {
      type: Number,
      default:1
    },
  },
  computed: {
    qrWidth() {
      let width = 0;
      if (this.sWidth) {
        width = this.sWidth; //370*320/(window.innerWidth)/20
      } else {
        width = 5.33; //250*320/(window.innerWidth)/20
      }
      return width;
    },
  },
  methods: {
    qrcode() {
      if (qrcode) {
        qrcode.clear();
        qrcode.makeCode(this.text)
      } else {
          qrcode = new QRcode('qrCode', {
          width: (this.qrWidth * 20 * window.innerWidth) / 320,
          height: (this.qrWidth * 20 * window.innerWidth) / 320, // 高度  ,250*320/(window.innerWidth)/20
          text: this.text, // 二维码内容
          image: "",
          correctLevel: QRcode.CorrectLevel.L,
        });
      }
    },
  },
  destroyed() {
    qrcode = null;
  }
}
</script>

<style scoped>
.qrcode {
  padding-top: 0.21333333rem;
  padding-bottom: 0.21333333rem;
}

.qrcode .qrcode_content {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div>
    <!--pc-->
    <div v-if="$terminal" class="login-back">
      <div class="login-form">
        <el-form
            :hide-required-asterisk="false"
            :scroll-to-error="true"
            :scroll-into-view-options="true"
            :model="loginForm"
            ref="loginRef"
            :rules="rulesForm"
            size="large"
            label-position="left">
          <el-form-item prop="account">
            <el-input
                @blur="exist"
                @keyup.enter.native="submitForm('loginRef')"
                :class="{'apply-shake': shakeaccount}"
                v-model="loginForm.account"
                :placeholder="$t('login.account')">
            </el-input>
          </el-form-item>
          <el-form-item v-if="!loginOrRegister" prop="email">
            <el-input
                @keyup.enter.native="submitForm('loginRef')"
                :class="{'apply-shake': shakeEmail}"
                v-model="loginForm.email"
                :placeholder="$t('login.email')">
            </el-input>
          </el-form-item>
          <el-form-item v-if="!loginOrRegister" prop="nickName">
            <el-input
                @keyup.enter.native="submitForm('loginRef')"
                :class="{'apply-shake': shakeName}"
                v-model="loginForm.nickName"
                :placeholder="$t('login.nickName')">
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
                @keyup.enter.native="submitForm('loginRef')"
                @blur="getOnePwd()"
                :class="{'apply-shake': shakePwd}"
                type="password"
                :show-password="true"
                v-model.lazy.trim="pwd"
                :placeholder="$t('login.password')"/>
          </el-form-item>
          <el-form-item prop="captcha">
            <el-row class="login-language" style="justify-content: space-between">
              <el-col :span="15">
                <el-input
                    @keyup.enter.native="submitForm('loginRef')"
                    :class="{'apply-shake': shakeCode}"
                    v-model="loginForm.captcha"
                    :placeholder="$t('login.code')"/>
              </el-col>
              <el-col :span="8" style="float:right;">
                <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item prop="registerCode">
            <el-row class="login-language" style="justify-content: space-between">
              <div v-if="true">
                <el-input
                    @keyup.enter.native="submitForm('loginRef')"
                    :class="{'apply-shake': shakeShakeCode}"
                    v-model="loginForm.registerCode"
                    :placeholder="$t('login.registerCode')"/>
              </div>
              <div v-else>
                <el-col :span="15">
                  <el-input
                      @keyup.enter.native="submitForm('loginRef')"
                      :class="{'apply-shake': shakeCode}"
                      v-model="loginForm.captcha"
                      :placeholder="$t('login.code')"/>
                </el-col>
                <el-col :span="8" style="float:right;">
                  <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                </el-col>
              </div>
            </el-row>
          </el-form-item>
          <el-form-item v-if="loginOrRegister" prop="otpNum">
            <el-row class="login-language" style="justify-content: space-between">
              <div v-if="true">
                <el-input
                    @keyup.enter.native="submitForm('loginRef')"
                    v-model="loginForm.otpNum"
                    :placeholder="$t('login.otpNum')"/>
              </div>
              <div v-else>
                <el-col :span="15">
                  <el-input
                      @keyup.enter.native="submitForm('loginRef')"
                      :class="{'apply-shake': shakeCode}"
                      v-model="loginForm.captcha"
                      :placeholder="$t('login.code')"/>
                </el-col>
                <el-col :span="8" style="float:right;">
                  <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                </el-col>
              </div>
            </el-row>
          </el-form-item>
          <el-form-item class="login-language">
            <el-radio-group @change="$store.dispatch('i18n')"
                            v-model="$store.state.i18n">
              <el-radio label="zh-CN">中文</el-radio>
              <el-radio label="en-US">English</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="form-btn">
            <div>
              <el-button style="width: 30%"
                         v-if="loginOrRegister"
                         class="register-button"
                         type="warning"
                         @click="loginOrRegister=!loginOrRegister"
              >{{ $t("login.register") }}
              </el-button>
              <el-button style="width: 30%"
                         v-else
                         class="register-button"
                         type="warning"
                         @click="submitForm('loginRef','register')"
              >{{ $t("login.register") }}
              </el-button>
              <el-button
                  v-if="loginOrRegister"
                  style="width: 60%"
                  class="login-button"
                  type="primary"
                  @click="submitForm('loginRef','login')"
              >{{ $t("login.login") }}
              </el-button>
              <el-button
                  v-else
                  style="width: 60%"
                  class="login-button"
                  type="primary"
                  @click="loginOrRegister=true;resForm('loginRef')"
              >{{ $t("login.return") }}
              </el-button>
            </div>
            <a class="forgotPassword" @click="dialogRestPwd=true">{{$t("login.forgotPassword")}}</a>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="!loginOrRegister &&loginForm.otpSecret!==''" class="qrcode-pc">
        扫描下方二维码添加手机验证码
        <QRCode ref="qrcode"
                :text="loginForm.otpPwd"
                :s-width="1"/>
      </div>
    </div>
    <!--移动端-->
    <div v-else class="m-login-back">
      <img class="login-img" src="../../assets/login/mLoginIndex.svg" alt="">
      <el-form
          :hide-required-asterisk="false"
          :scroll-to-error="true"
          :scroll-into-view-options="true"
          :model="loginForm"
          ref="loginRef"
          :rules="rulesForm"
          size="large"
          label-position="left"
          class="m-login-form">
        <el-form-item v-if="!loginOrRegister" prop="account">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeaccount}"
              v-model="loginForm.account"
              :placeholder="$t('login.account')">
          </el-input>
        </el-form-item>
        <el-form-item v-if="!loginOrRegister" prop="email">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeEmail}"
              v-model="loginForm.email"
              :placeholder="$t('login.email')">
          </el-input>
        </el-form-item>
        <el-form-item prop="nickName">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeName}"
              v-model="loginForm.nickName"
              :placeholder="$t('login.nickName')">
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakePwd}"
              type="password"
              :show-password="true"
              v-model.lazy.trim="pwd"
              :placeholder="$t('login.password')"/>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-row class="login-language" style="justify-content: space-between">
            <div>
              <el-col :span="15">
                <el-input
                    @keyup.enter.native="submitForm('loginRef')"
                    :class="{'apply-shake': shakeCode}"
                    v-model="loginForm.captcha"
                    :placeholder="$t('login.code')"/>
              </el-col>
              <el-col :span="8" style="float:right;">
                <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
              </el-col>
            </div>
          </el-row>
        </el-form-item>
        <el-form-item class="login-language">
          <el-radio-group @change="$store.dispatch('i18n')" v-model="$store.state.i18n">
            <el-radio label="zh-CN">中文</el-radio>
            <el-radio label="en-US">English</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-btn">
          <div>
            <el-button style="width: 30%"
                       v-if="loginOrRegister"
                       class="register-button"
                       type="warning"
                       @click="loginOrRegister=!loginOrRegister"
            >{{ $t("login.register") }}
            </el-button>
            <el-button style="width: 30%"
                       v-else
                       class="register-button"
                       type="warning"
                       @click="submitForm('loginRef','register')"
            >{{ $t("login.register") }}
            </el-button>
            <el-button
                v-if="loginOrRegister"
                style="width: 60%"
                class="login-button"
                type="primary"
                @click="submitForm('loginRef','login')"
            >{{ $t("login.login") }}
            </el-button>
            <el-button
                v-else
                style="width: 60%"
                class="login-button"
                type="primary"
                @click="loginOrRegister=true;resForm('loginRef')"
            >{{ $t("login.return") }}
            </el-button>
          </div>
          <a class="forgotPassword" @click="resetPwd">{{$t("login.forgotPassword")}}</a>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogRestPwd"
        width="30%">
      <el-form :model="restForm"
               :rules="restFormRules"
               ref="restForm"
               label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="帐号" prop="account">
          <el-row>
            <el-col :span="15">
              <el-input :class="{'apply-shake': shakeaccount}" v-model="restForm.account" placeholder="帐号"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button style="float:right;" type="primary" plain @click="resetPwd('restForm','send')">发送验证码</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <!--主要是为了复用-->
        <el-form-item label="邮箱验证码" prop="mailCode">
          <el-input :class="{'apply-shake': shakePwd}" v-model="restForm.mailCode" placeholder="邮箱验证码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha">
          <el-row>
            <el-col :span="15">
              <el-input :class="{'apply-shake': shakeCode}" v-model="restForm.captcha" placeholder="验证码"></el-input>
            </el-col>
            <el-col :span="8">
              <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogRestPwd = false">取 消</el-button>
        <el-button type="primary" @click="resetPwd('restForm','reset')">重 置</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '../../assets/style/myshake.scss'
import {codeAPI, existAPI, loginAPI, mailCodeVerifyAPI, onePwdAPI, registerAPI, sendMailAPI} from "@/api/Login";
import {encryptText} from "@/utils/jsencrypt";
import QRCode from "@/components/QRCode.vue";
import common from "@/mixin/common";
let that=''
const checkaccount =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeaccount=true
    setTimeout(()=>{
      that.shakeaccount=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkEmail =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeEmail=true
    setTimeout(()=>{
      that.shakeEmail=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checknickName =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeName=true
    setTimeout(()=>{
      that.shakeName=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkPwd =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakePwd=true
    setTimeout(()=>{
      that.shakePwd=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkCode =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeCode=true
    setTimeout(()=>{
      that.shakeCode=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
let interval=null
export default {
  name: "Login",
  mixins:[common],
  components: {QRCode},
  data(){
    return{
      dialogRestPwd:false,
      loginOrRegister: true,
      shakeCode:false,
      shakePwd:false,
      shakeName:false,
      shakeaccount:false,
      shakeEmail:false,
      shakeShakeCode:false,
      codeImageUrl: '',
      pwd: '',
      loginForm:{
        nickName: '',
        password: '123456',
        uuid: '',
        captcha: '',
        account: '123456',
        email: '',
        registerCode: '',
        otpPwd: '',
        otpSecret: '',
        otpNum: '',
      },
      rulesForm:{
        nickName: [
          {validator:checknickName, trigger: "blur"},
        ],
        password: [
          {validator:checkPwd, trigger: "blur"},
        ],
        account: [
          {validator:checkaccount, trigger: "blur"},
        ],
        email: [
          {validator:checkEmail, trigger: "blur"},
        ],
        captcha: [
          {validator:checkCode, trigger: "blur"},
        ],
      },
      restForm:{
        mailCode:'',
        account:'',
        captcha:'',
        uuid:''
      },
      restFormRules:{
        account:[
          {validator:checkaccount, trigger: "blur"},
        ],
        captcha: [
          {validator:checkCode, trigger: "blur"},
        ],
        mailCode: [
          {validator:checkPwd, trigger: "blur"},
        ],
      },
    }
  },
  created() {
    this.getCode()
  },
  mounted() {
    that = this;
    if (interval === null) {
      interval=setInterval(()=>{
        that.getCode()
      },110*1000);
    }
  },
  watch:{
    pwd:{
      handler(n, o) {
        this.loginForm.password=encryptText(n)
      },
      immediate:false,
    }
  },
  methods:{
    resetPwd(formName,type) {
      if (type === 'send') {
        if (this.restForm.account) {
          this.$refs.restForm.validateField('account',(valid)=>{
            if (this.restForm.captcha) {
              this.$refs.restForm.validateField('captcha',(valid)=>{
                sendMailAPI(this.restForm).then(res=>{
                  if (res.code === 200) {
                    this.popUp(res)
                  }
                })
              })
            } else {
              this.$refs.restForm.validateField('captcha')
            }
          })
        } else {
          this.$refs.restForm.validateField('account')
          return;
        }
      }
      if (type === 'reset') {
        //校验帐号
        if (this.restForm.account) {
          this.$refs.restForm.validateField('account',(valid)=>{
            //校验验证码
            if (this.restForm.captcha) {
              this.$refs.restForm.validateField('captcha',(valid)=>{
                //校验邮箱验证码
                if (this.restForm.mailCode) {
                  this.$refs.restForm.validateField('mailCode',(valid)=>{
                    mailCodeVerifyAPI(this.restForm).then(res=>{
                      if (res.code === 200) {
                        this.popUp(res)
                        this.dialogRestPwd = false
                      }
                    })
                  })
                } else {
                  this.$refs.restForm.validateField('mailCode')
                }
              })
            } else {
              this.$refs.restForm.validateField('captcha')
            }
          })
        } else {
          this.$refs.restForm.validateField('account')
        }
      }
    },
    async getCode(){
      let res= await codeAPI();
      this.codeImageUrl = res.data.verifyCode;
      this.loginForm.uuid = res.data.uuid
      this.restForm.uuid = res.data.uuid
    },
    submitForm(formName,type){
      if (type === 'register') {
        this.loginOrRegister=false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.loginOrRegister) {
            loginAPI(this.loginForm).then(res=>{
              if (res) {
                if (res.code === 200) {
                  this.popUp(res);
                  this.$store.dispatch('pushPath',res.data.menuList);
                  sessionStorage.setItem('userInfo',JSON.stringify(res.data));
                  sessionStorage.setItem('menuList',JSON.stringify(res.data.menuList));
                }
              }
            });
          } else {
            registerAPI(this.loginForm).then(res=>{
              this.popUp(res)
              if (res.code === 200) {
                this.loginOrRegister=!this.loginOrRegister
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resForm(formName){
      this.$refs[formName].resetFields();
    },
    /*async getOnePwd(){
      const {password}=this.loginForm
      if (password) {
        const data = await onePwdAPI({password});
        this.loginForm.otpPwd=data.data.otpPwd
        this.loginForm.otpSecret=data.data.otpSecret
        console.log(this.loginForm)
        this.$refs.qrcode.qrcode()
        this.$refs.qrcode.qrcode()
      }
    },*/
    getOnePwd(){
      const {password}=this.loginForm
      if (password && !this.loginOrRegister) {
        onePwdAPI({password}).then(res=>{
          this.loginForm.otpPwd=res.data
          this.loginForm.otpSecret=res.data.split('=')[1]
          setTimeout(()=>{
            that.$refs.qrcode.qrcode()
          },500)
        })
      }
    },
    exist(){
      const {account}=this.loginForm
      if (account && !this.loginOrRegister) {
        existAPI({account}).then(res=>{
          console.log(res)
          this.popUpError(res)
          if (res.code === 200) {
            if (res.data === false) {
              this.ok('帐号未重复')
            } else {
              this.error('帐号重复, 请修改后再添加')
            }
          }
        });
      }
    },
  },
  filters: {},
  beforeDestroy() {
    if (interval !== null) {
      clearInterval(interval);
    }
  }
}
</script>

<style scoped lang="scss">
.login-back{
  height: 100vh;
  background: url('../../assets/login/login.png') no-repeat;
  background-size: cover;
  position:relative;
  overflow: hidden;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  //display: flex;
}
.form-btn{
  //display: flex;
  //justify-content: space-between;
}
.register-button{

}
.login-button{
  float: right;
}
.forgotPassword{
  float: right;
  cursor: pointer;
}
.forgotPassword:hover{
  float: right;
  color: #F56C6C;
  cursor: pointer;
}
.login-form{
  width: 300px;
  position: relative;
  left: 18%;
}
.m-login-back{
  width: 80%;
  margin: 0 auto;
  //background-color: white;
  .login-img{
    width: 100%;
    margin: 0 auto;
  }
}
.login-code{
  width: 100%;
  height: 40px;
  //border: solid 1px;
  cursor: pointer;
}
.qrcode-pc{
  position: absolute;
  text-align: center;
  top: 20%;
}
</style>

const payType = [
    {type:0, name:'微信',icon:'wechatPay.svg',disabled:true},
    {type:1, name:'支付宝',icon:'aliPay.svg',disabled:false},
    {type:2, name:'银行卡',icon:'bankPay.svg',disabled:false},
]
const userState = [
    {type:0, name:'申请注册'},
    {type:1, name:'正常'},
    {type:2, name:'拒绝注册'},
    {type:3, name:'拉黑'},
    {type:4, name:'删除'},
]
//顺序不可变动
const payState = [
    {type:0, name:'待支付',show:true},
    {type:1, name:'支付中',show:true},
    {type:2, name:'已支付待确认',show:true},
    {type:3, name:'支付失败',show:true},
    {type:4, name:'申诉中',show:true},
    {type:5, name:'退款中',show:true},
    {type:6, name:'已退款',show:true},
    {type:7, name:'订单取消',show:true},
    {type:8, name:'锁定订单',show:true},
    {type:9, name:'提醒卖家',show:false},
    {type:-1, name:'订单完成',show:false},
]
const businessState = [
    {type:0, name:'关闭盈利'},
    {type:1, name:'开启盈利'},
]
const roleList = [
    {type:1, name:'超级管理员'},
    {type:2, name:'商家'},
    {type:3, name:'消费者'},
    {type:4, name:'外部调用'},
]
const messageType = [
    {type:0, name:'聊天'},
    {type:1, name:'pong'},
    {type:2, name:'订单消息'},
    {type:3, name:'商家提醒'},
]
const roleMap={
    admin:1,
    business:2,
    consumer:3,
    api:4
}
const phoneVerify = [
    {type:0, name:'关闭'},
    {type:1, name:'开启'},
]
export default {
    payType,
    userState,
    payState,
    businessState,
    roleList,
    messageType,
    roleMap,
    phoneVerify,
}

<template>
  <div class="hard-menu">
    <el-menu
        :router="true"
        :default-active="activeNum"
        class="el-menu-vertical-demo"
        @select="menuSelect"
        background-color="#545c64"
        text-color="#fff"
        :active-text-color="activeNum==='1'?'#fff':'#ffd04b'">
      <el-menu-item :index="item.path" v-for="(item,index) in menuList">
        <i :class="item.icon"></i>
        <span slot="title">{{item.name}}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {getAdminRouteList, getRouteList} from "@/router";

export default {
  name: "admin-menu",
  components:{},
  data() {
    return {
      activeNum: '/admin/user',
      menuList: getAdminRouteList()
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
    },
    menuSelect(key, keyPath) {
      console.log(key)
      if (key === '1') {
        this.activeNum = key;
        this.dialogForm=true
      } else {
        this.activeNum = key
      }
    }
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
.hard-menu{
  width: 150px;
}
.el-submenu .el-menu-item{
  min-width: 100%;
}
.icon-menu{
  color: white
}
</style>

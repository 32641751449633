
import constant from "@/constant";

export default {
  filters:{
    payTypeTransfer(value,direction){
      let str
      for (let payTypeElement of constant.payType) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
    payStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.payState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          if (value === 8) {
            str='未下单'
          }
          break;
        }
      }
      return str
    },
    userStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.userState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
    businessStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.businessState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
  }
}

import clipboard from 'clipboard';
export default {
  methods:{
    goPage(path){
      this.$router.push({path:path})
    },
    copyText(text){
      clipboard.copy(text)
      this.$message({
        message: '复制成功',
        type: 'success',
        showClose:true
      })
    },
    ok(message){
      this.$message.success({message:message,showClose:true})
    },
    error(message){
      this.$message.error({message:message,showClose:true})
    },
    popUp(res){
      if (res.code === 200) {
        this.$message.success({message:res.msg,showClose:true,duration:500})
      } else if (res.code >= 400) {
        this.error(res.msg)
      }
    },
    popUpError(res){
      if (res.code === 500) {
        this.$message.error({message:res.msg,showClose:true})
      }
    },
  }
}
